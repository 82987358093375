import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Abouttext extends Component {
    render() {
        return (
            <section className="section sigma_about bg-cover bg-norepeat" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/assets/img/aboutbg.png)" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="sigma_about-content">
                                <div className="section-title text-center">
                                    <h6 className="subtitle cursive-style">About Us</h6>
                                    <h4 className="title">We are a Church That Believes in <span className="custom-primary">Jesus</span> </h4>
                                    <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae</p>
                                </div>
                                <div className="sigma_about-text bg-norepeat bg-contain bg-center" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/assets/img/cross-icon.png)" }}>
                                    <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years,
                                        sometimes by accident, sometimes on purpose (injected humour and the like). If you are going to use a passage of Lorem Ipsum.</p>
                                    <p>You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined. chunks as necessary.</p>
                                </div>
                                <div className="section-button">
                                    <Link to="/event" className="sigma_btn-custom">Explore Events</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Abouttext;