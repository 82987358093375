import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axiosInstance from "../../../hooks/api";

const NasDocRequestForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [docType, setDocType] = useState("baptism");
  const [pickType, setPickType] = useState("pickup");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const BAPTISM = "baptism";
  const COMMUNION = "communion";
  const CONFIRMATION = "confirmation";
  const MARRIAGE = "marriage";

  const onSubmit = async (data) => {
    try {
      const requesterPayload = {
        requestor_name: data.requester_name,
        email: data.requester_email,
        phone: data.requester_phone,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        zip: data.zip,
        state: data.state,
        method: data.method === "pickup" ? "pickup" : "mail",
      };

      const isForMarriage = data.req_reason === MARRIAGE;

      const docRequestPaylod = {
        ...{
          baptism: data.req_reason === BAPTISM,
          first_communion: data.req_reason === COMMUNION,
          confirmation: data.req_reason === CONFIRMATION,
          matrimony: data.req_reason === MARRIAGE,
        },
        ...(isForMarriage
          ? {
              bride_name: data.bride_name,
              groom_name: data.groom_name,
              recipient_name: "-",
            }
          : {
              recipient_name: data.child_name,
              recipient_mother: data.mother_name,
              recipient_father: data.father_name,
            }),
        ...requesterPayload,
      };

      setIsLoading(true);

      const submissionResponse = await axiosInstance.post(
        "/public/docRequests",
        { ...docRequestPaylod }
      );

      reset();

      Swal.fire({
        title: t("_request_placed"),
        timer: 1500,
        confirmButtonColor: "#032C65",
        confirmButtonText: "Ok",
        background: "#f7f8fa",
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="section pt-0">
      <div className="container">
        <div className="section-title text-center">
          <h6 className="subtitle cursive-style">
            {t("_document_request_form")}
          </h6>
          <p>{t("_donation_message")}</p>
        </div>
        <div className="sigma_contact-wrap">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-12">
              <div className="sigma_contact-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-12">
                        <label>
                          <em className="text-danger">
                            * {t("_all_docs_provisional_copies")}
                          </em>
                        </label>
                      </div>
                      <div className="col-12">
                        <span className="text-danger">
                          {t("_stop_donation")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      {t("_reason")} <em className="text-danger">*</em>{" "}
                    </label>
                    <div className="d-block row">
                      {[
                        { id: "baptism", label: "_baptism" },
                        { id: "communion", label: "_communion" },
                        { id: "confirmation", label: "_confirmation" },
                        { id: "marriage", label: "_marriage" },
                      ].map((item, idx) => (
                        <div
                          key={`req-${idx}`}
                          className="custom-control custom-radio custom-control-inline col-4 col-md-3"
                        >
                          <input
                            required
                            type="radio"
                            id={item.id}
                            name="req_reason"
                            className="custom-control-input"
                            value={item.id}
                            onClick={() => setDocType(item.id)}
                            checked={item.id === docType}
                            {...register("req_reason")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.id}
                          >
                            {t(item.label)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {docType !== MARRIAGE ? (
                    <>
                      <div className="form-group sa-top-input-form">
                        <div className="row">
                          <div className="col-sm-12">
                            <label>
                              {t("_child_name")}{" "}
                              <em className="text-danger">*</em>
                            </label>
                            <input
                              type="text"
                              required
                              name="child_name"
                              className="form-control border"
                              placeholder={t("_name_placeholder")}
                              {...register("child_name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group sa-top-input-form">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>
                              {t("_mother_name")}{" "}
                              <em className="text-danger">*</em>
                            </label>
                            <input
                              type="text"
                              required
                              name="mother_name"
                              className="form-control border"
                              placeholder={t("_name_placeholder")}
                              {...register("mother_name")}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>
                              {t("_father_name")}{" "}
                              <em className="text-danger">*</em>
                            </label>
                            <input
                              type="text"
                              required
                              name="father_name"
                              className="form-control border"
                              placeholder={t("_name_placeholder")}
                              {...register("father_name")}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="form-group sa-top-input-form">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>
                            {t("_bride_name")}{" "}
                            <em className="text-danger">*</em>
                          </label>
                          <input
                            type="text"
                            required
                            name="bride_name"
                            className="form-control border"
                            placeholder={t("_name_placeholder")}
                            {...register("bride_name")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>
                            {t("_groom_name")}{" "}
                            <em className="text-danger">*</em>{" "}
                          </label>
                          <input
                            type="text"
                            required
                            name="groom_name"
                            className="form-control border"
                            placeholder={t("_name_placeholder")}
                            {...register("groom_name")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_requester_name")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="requester_name"
                          className="form-control border"
                          placeholder={t("_name_placeholder")}
                          {...register("requester_name")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_requester_phone")}{" "}
                          <em className="text-danger">*</em>{" "}
                        </label>
                        <input
                          type="tel"
                          required
                          name="requester_phone"
                          className="form-control border"
                          placeholder="212-395-9280"
                          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          {...register("requester_phone")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_email")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="email"
                          required
                          name="requester_email"
                          className="form-control border"
                          placeholder={t("_your_email")}
                          {...register("requester_email")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_address_1")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="address_line_1"
                          className="form-control border"
                          placeholder="42"
                          {...register("address_line_1")}
                        />
                      </div>
                      <div className="col-sm-12">
                        <label>
                          {t("_address_2")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          name="address_line_2"
                          className="form-control border"
                          placeholder="Wallaby Way"
                          {...register("address_line_2")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          {t("_city")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="city"
                          className="form-control border"
                          placeholder="Sydney"
                          {...register("city")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_state")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="state"
                          className="form-control border"
                          placeholder="Sydney"
                          {...register("state")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_zip")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="zip"
                          className="form-control border"
                          placeholder="42"
                          {...register("zip")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      {t("_delivery_method")} <em className="text-danger">*</em>{" "}
                    </label>
                    <div className="d-block row">
                      {[
                        { id: "pickup", label: "_pickup" },
                        { id: "mail", label: "_mail" },
                      ].map((item, idx) => (
                        <div
                          key={`pick-${idx}`}
                          className="custom-control custom-radio custom-control-inline col-4 col-md-3"
                        >
                          <input
                            required
                            type="radio"
                            id={item.id}
                            name="pick_type"
                            className="custom-control-input"
                            value={item.id}
                            onClick={() => setPickType(item.id)}
                            checked={item.id === pickType}
                            {...register("method")}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.id}
                          >
                            {t(item.label)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className={
                        isLoading
                          ? "sigma_btn-custom"
                          : "sigma_btn-custom light"
                      }
                      name="button"
                      disabled={isLoading}
                      style={{
                        backgroundColor: isLoading ? "grey" : "",
                      }}
                    >
                      {t("_place_request")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NasDocRequestForm;
