import React, { Component } from "react";
import { Link } from "react-router-dom";
import category from "../../../data/category.json";
import churchData from "../../../data/_nas/churchData";
import { withTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

class NasFooter extends Component {
  render() {
    const { t, i18n } = this.props;

    return (
      <footer className="sigma_footer footer-2">
        {/* Top Footer */}
        <div className="sigma_footer-top secondary-bg">
          <div className="container">
            <div className="sigma_footer-logo sigma_footer-top-item">
              <img
                src={"/assets/img/nas/logo-white.png"}
                width="67%"
                alt="logo"
              />
            </div>
            <div className="sigma_footer-sm sigma_footer-top-item">
              <ul className="sigma_sm">
                <Dropdown variant="secondary" style={{ marginRight: "2em" }}>
                  <Dropdown.Toggle variant="secondary">
                    {t("_language")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        i18n.changeLanguage("en");
                      }}
                    >
                      {t("_en")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        i18n.changeLanguage("es");
                      }}
                    >
                      {t("_es")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <li>
                  <a href={churchData.yt.link}>
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Middle Footer */}
        <div className="sigma_footer-middle secondary-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 footer-widget">
                <h5 className="widget-title">{t("_contact_us")}</h5>
                <p>{churchData.location}</p>
                <p>
                  {t("_email")}:{" "}
                  <a
                    rel={"external"}
                    href={`mailto:${churchData.contactEmail}`}
                  >
                    {churchData.contactEmail}
                  </a>
                </p>
                <p>
                  {t("_phone")}: {churchData.phone}
                </p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 footer-widget">
                <h5 className="widget-title">{t("_information")}</h5>
                <ul>
                  <li>
                    <Link to="/">{t("_home")}</Link>
                  </li>
                  <li>
                    <Link to="/blog">{t("_blog")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="sigma_footer-bottom secondary-bg">
          <div className="container">
            <div className="sigma_footer-copyright">
              <p>
                {t("_copyright")} 2024. {t("_all_rights_reserved")}. | Made with
                Love &#128153;{" "}
                <a
                  href="https://www.nassoftwarecorp.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>NAS Software</b>
                </a>
              </p>
              <Link
                to="#"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                className="sigma_back-to-top"
              >
                {t("_back_to_top")}
                <div className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="far fa-chevron-up" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(NasFooter);
