import React, { Component } from 'react';
import sermonpost from "../../../data/sermon/sermon.json";
import { getAuthor } from "../../../helper/helper";
import { Link } from 'react-router-dom';
import { Accordion, Card, Button } from 'react-bootstrap';

class Recentsermons extends Component {
    render() {
        return (
            <div className="sigma_sermon-box">
                <div className="vertical-between mb-4">
                    <h4 className="mb-0">Latest Sermons</h4>
                    <Link to="/sermon" className="sigma_btn-custom primary btn-sm">View All</Link>
                </div>
                <Accordion defaultActiveKey={1} className="sigma_sermon-wrap">
                    {sermonpost.map((item, i) => (
                        <Card key={i}>
                            <Accordion.Collapse eventKey={1 + i} className="collapseparent">
                                <Card.Body>
                                    <ul className="mb-0">
                                        {getAuthor(item.author).map((author, i) => (
                                            <li key={i}>
                                                <img src={process.env.PUBLIC_URL + "/" + author.image} alt={author.name} />
                                                Message from
                                                <Link to={"/sermon/author/" + author.id} className="fw-600"> {author.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <ul className="custom_flex sigma_block-icons">
                                        {/* Data */}
                                        {item.includes.map((include, i) => (
                                            <li key={i}>
                                                <a rel={"external"} href={include.url} className="vertical-center">
                                                    <i className={include.icon} />
                                                </a>
                                            </li>
                                        ))}
                                        {/* Data */}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={1 + i}>
                                    {item.title}
                                </Accordion.Toggle>
                            </Card.Header>
                        </Card>
                    ))}
                </Accordion>
            </div>
        );
    }
}

export default Recentsermons;