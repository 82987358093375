import React, { Component } from 'react';
import { getRecentPost, getDateInitial } from "../../../helper/eventHelper";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    vertical: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 2000
}
 
class Recentevents extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        return (
            <div className="sigma_event-box">
                <div className="vertical-between mb-4">
                    <h4 className="mb-0">Latest Event</h4>
                    {/* Custom Arrows Start */}
                    <div className="sigma_arrows sigma_arrows-transparent sigma_arrows-dark sigma_arrows-sm">
                        <div className="sigma_round-arrow prev-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                            </svg>
                            <i className="far fa-chevron-up slick-arrow slider-prev" onClick={this.previous} />
                        </div>
                        <div className="sigma_round-arrow next-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                            </svg>
                            <i className="far fa-chevron-down slick-arrow slider-next" onClick={this.next} />
                        </div>
                    </div>
                    {/* Custom Arrows End */}
                </div>
                <Slider ref={c => (this.slider = c)} {...settings} className="sigma_event-wrap sigma_event-slider">
                    {/* Data */}
                    {getRecentPost().map((item, i) => (
                        <div key={i}>
                            <li className="vertical-between d-flex">
                                <div className="sigma_event-date" dangerouslySetInnerHTML={{ __html: getDateInitial(item.date) }} />
                                <div className="sigma_event-content">
                                    <h5 className="mb-0"> <Link to={"/event-details/" + item.id}>{item.title.slice(0, 25)}..</Link></h5>
                                    <ul className="mb-0">
                                        <li><i className="fal fa-clock custom-primary mr-3" />{item.timeday}</li>
                                        <li><i className="fal fa-map-marker custom-primary mr-3" />{item.location}</li>
                                    </ul>
                                </div>
                            </li>
                        </div>
                    ))}
                    {/* Data */}
                </Slider>
            </div>
        );
    }
}

export default Recentevents;