import React, { Component } from 'react';
import block from "../../../data/ctablock.json";

class Blockcta extends Component {
    render() {
        return (
            <div className="section section-padding pt-0">
                <div className="container">
                    <div className="row">
                        {/* Data */}
                        {block.map((item, i) => (
                            <div className="col-sm-6" key={i}>
                                <div className="sigma_icon-block icon-block-5">
                                    <i className={item.icon} />
                                    <div className="sigma_icon-block-content">
                                        <h5>{item.title}</h5>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* Data */}
                    </div>
                </div>
                <img src={process.env.PUBLIC_URL + "/assets/img/icons-bg.jpg"} className="custom-img-1" alt="img" />
            </div>
        );
    }
}

export default Blockcta;