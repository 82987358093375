import React, { Component } from "react";
import { Link } from "react-router-dom";
import NasMobileMenu from "./NasMobileMenu";
import NasCanvas from "./NasCanvas";
import navigation from "../../../data/_nas/navigation.json";
import churchData from "../../../data/_nas/churchData";
import { withTranslation } from "react-i18next";
import NasDonationModal from "./NasDonationModal";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Nav toggle
      navmethod: false,
      // Search
      searchMethod: false,
      // Cart
      cartMethod: false,
      // Canvas
      canvasMethod: false,
      // Sticky
      windowSize: "",
      stickyheader: 0,
      // Donation Modal
      showModal: false,
    };
    // Nav toggle
    this.toggleNav = this.toggleNav.bind(this);
    // Search
    this.toggleSearch = this.toggleSearch.bind(this);
    // Cart
    this.toggleCart = this.toggleCart.bind(this);
    // Canvas
    this.toggleCanvas = this.toggleCanvas.bind(this);
    // Donation modal
    this.toggleModal = this.toggleModal.bind(this);
  }
  // Nav toggle
  toggleNav() {
    this.setState({
      navmethod: !this.state.navmethod,
    });
  }
  // Search toggle
  toggleSearch() {
    this.setState({
      searchMethod: !this.state.searchMethod,
    });
  }
  // Cart toggle
  toggleCart() {
    this.setState({
      cartMethod: !this.state.cartMethod,
    });
  }
  // Canvas toggle
  toggleCanvas() {
    this.setState({
      canvasMethod: !this.state.canvasMethod,
    });
  }
  // Donation modal toggle
  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }
  // Sticky header
  StickyHeader = (e) => {
    const windowSize = window.scrollY;
    const stickyheader = windowSize > 100;
    this.setState((prevState) => {
      return {
        stickyheader,
      };
    });
  };
  componentDidMount() {
    window.addEventListener("scroll", this.StickyHeader);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.StickyHeader);
  }
  // Mobile menu
  getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  triggerChild = (e) => {
    let subMenu = "";

    subMenu =
      this.getNextSibling(e.target, ".sub-menu") !== undefined
        ? this.getNextSibling(e.target, ".sub-menu")
        : null;

    if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
      subMenu.classList = subMenu.classList.contains("d-block")
        ? "sub-menu"
        : "sub-menu d-block";
    }
  };
  render() {
    return <></>;
  }
}

class NasHeader extends HeaderComponent {
  render() {
    const stickyHeader = this.state.stickyheader ? " sticky" : "";

    const { t } = this.props;

    return (
      <>
        {/* Search Form End*/}
        <div
          className="sigma_aside-overlay sigma_cart-sidebar-overlay sigma_cart-trigger"
          onClick={this.toggleCart}
        />
        {/* Aside (Right Panel) */}
        <aside
          className={
            this.state.canvasMethod === true
              ? "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg open"
              : "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg"
          }
        >
          <NasCanvas />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger-right"
          onClick={this.toggleCanvas}
        />
        {/* Aside (Mobile Navigation) */}
        <aside
          className={
            this.state.navmethod === true
              ? "sigma_aside sigma_aside-left open"
              : "sigma_aside sigma_aside-left"
          }
        >
          <NasMobileMenu />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger-left"
          onClick={this.toggleNav}
        />
        {/* Header */}
        <header
          className={
            "sigma_header header-3 header-fw can-sticky crayon-texture-1" +
            stickyHeader
          }
        >
          {/* Topheader Start */}
          <div className="sigma_header-top">
            <div className="container">
              <div className="sigma_header-top-inner">
                <ul className="sigma_header-top-links">
                  <li className="menu-item">
                    <Link to="#">
                      <i className="fal fa-phone" /> {` ${churchData.phone}`}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="#">
                      <i className="fal fa-envelope" />
                      {` ${churchData.contactEmail}`}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Topheader End */}
          {/* Middle Header Start */}
          <div className="sigma_header-middle">
            <div className="container">
              <nav className="navbar">
                {/* Logo Start */}
                <div className="sigma_logo-wrapper">
                  <Link to="/" className="navbar-brand">
                    <img src="/assets/img/nas/logo-red.png" alt="logo" />
                  </Link>
                  {/* Logo Card Start */}
                  <div className="sigma_logo-card style-2 d-none d-md-block">
                    <img
                      className="sigma_logo-card-texture"
                      src={
                        process.env.PUBLIC_URL + "/assets/img/cross-icon.png"
                      }
                      alt="img"
                    />
                    <div className="row">
                      <div className="col-sm-10">
                        <ul className="sigma_logo-card-info">
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-phone" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                <strong>{t("_call_us")}</strong>
                              </p>
                              <p>{churchData.phone}</p>
                            </div>
                          </li>
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-envelope" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                <strong>{t("_email_us")}</strong>
                              </p>
                              <p>{churchData.contactEmail}</p>
                            </div>
                          </li>
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-map-marker" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                <strong>{t("_find_us")}</strong>
                              </p>
                              <p>
                                <a href={churchData.googleMapsLink}>
                                  {t("_open") +
                                    " " +
                                    t("_in") +
                                    " " +
                                    t("_google_maps") +
                                    " "}
                                </a>
                              </p>
                            </div>
                          </li>
                        </ul>
                        <ul className="sigma_sm">
                          <li>
                            <a href={churchData.yt.link}>
                              <i className="fab fa-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* Logo Card End */}
                </div>
                {/* Logo End */}
                {/* Menu */}
                <ul className="navbar-nav">
                  {(navigation || [])
                    .filter((item) => item.id > 0)
                    .map((item, i) => (
                      <li
                        key={`menu-item-${i}`}
                        className={`menu-item ${
                          item.child ? "menu-item-has-children" : ""
                        } `}
                        onClick={this.triggerChild}
                      >
                        {item.child ? (
                          <Link onClick={(e) => e.preventDefault()} to="/">
                            {t(item.linkText)}
                          </Link>
                        ) : (
                          <Link to={item.link}> {t(item.linkText)} </Link>
                        )}
                        {item.child ? (
                          <ul className="sub-menu" role="menu">
                            {item.submenu.map((sub_item, i) => (
                              <li
                                key={i}
                                className={`menu-item ${
                                  sub_item.child ? "menu-item-has-children" : ""
                                } `}
                              >
                                {sub_item.child ? (
                                  <Link
                                    onClick={(e) => e.preventDefault()}
                                    to="/"
                                  >
                                    {t(sub_item.linkText)}
                                  </Link>
                                ) : (
                                  <Link to={sub_item.link}>
                                    {t(sub_item.linkText)}
                                  </Link>
                                )}
                                {sub_item.submenu ? (
                                  <ul className="sub-menu">
                                    {sub_item.submenu.map((third_item, i) => (
                                      <li className="menu-item" key={i}>
                                        <Link to={third_item.link}>
                                          {t(third_item.linkText)}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ))}
                </ul>
                {/* Controls */}
                <div className="sigma_header-controls style-2">
                  <ul className="sigma_header-controls-inner">
                    <li
                      className="aside-toggler style-2 aside-trigger-left"
                      onClick={this.toggleNav}
                    >
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          {/* Middle Header End */}
          {/* Bottom Header Start */}
          <div className="sigma_header-bottom">
            <div className="container">
              <div className="sigma_header-bottom-inner">
                {/* Menu */}
                <ul className="navbar-nav">
                  {navigation
                    .filter((item) => item.id < 0)
                    .map((item, i) => (
                      <li
                        key={i}
                        className={`menu-item ${
                          item.child ? "menu-item-has-children" : ""
                        } `}
                        onClick={this.triggerChild}
                      >
                        {item.child ? (
                          <Link onClick={(e) => e.preventDefault()} to="/">
                            {t(item.linkText)}
                          </Link>
                        ) : (
                          <Link to={item.link}> {t(item.linkText)} </Link>
                        )}
                        {item.child ? (
                          <ul className="sub-menu" role="menu">
                            {item.submenu.map((sub_item, i) => (
                              <li
                                key={i}
                                className={`menu-item ${
                                  sub_item.child ? "menu-item-has-children" : ""
                                } `}
                              >
                                {sub_item.child ? (
                                  <Link
                                    onClick={(e) => e.preventDefault()}
                                    to="/"
                                  >
                                    {t(sub_item.linkText)}
                                  </Link>
                                ) : (
                                  <Link to={sub_item.link}>
                                    {t(sub_item.linkText)}
                                  </Link>
                                )}
                                {sub_item.submenu ? (
                                  <ul className="sub-menu">
                                    {sub_item.submenu.map((third_item, i) => (
                                      <li className="menu-item" key={i}>
                                        <Link to={third_item.link}>
                                          {third_item.linkText}
                                          {t(third_item.linkText)}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ))}
                </ul>
                <ul className="sigma_sm mt-2 mb-2">
                  <li>
                    <a href={churchData.yt.link}>
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                </ul>
                <div
                  className="sigma_header-bottom-cta"
                  onClick={() => this.toggleModal()}
                >
                  <Link to="#" className="sigma_btn-custom">
                    {t("_donate") + " " + t("_now")}
                  </Link>
                  <NasDonationModal
                    show={this.state.showModal}
                    setShow={() => this.setState({ showModal: false })}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Bottom Header End */}
        </header>
      </>
    );
  }
}

export default withTranslation()(NasHeader);
