import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import churchData from "../../../data/_nas/churchData";

class NasCanvas extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="sidebar d-block">
        <div className="sidebar-widget">
          <img
            src={process.env.PUBLIC_URL + "/assets/nas/logo-red.png"}
            className="mb-30"
            alt="img"
          />
          <p>
            NON NULLA sit amet nisl tempus convallis quis ac lectus. Donec
            rutrum congue leo eget malesuada. Praesent sapien massa, convallis a
            pellentesque nec, egestas non nisi.
          </p>
        </div>
        {/* Social Media Start */}
        <div className="sidebar-widget">
          <h5 className="widget-title">{t("_follow_us")}</h5>
          <div className="sigma_post-share">
            <ul className="sigma_sm">
              <li>
                <a href={churchData.fb.link} className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href={churchData.li.link} className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href={churchData.yt.link} className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Social Media End */}
      </div>
    );
  }
}

export default withTranslation()(NasCanvas);
