import React, { Component, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import Pagination from "react-js-pagination";
import NasHeader from "../../layouts/_nas/NasHeader";
import NasFooter from "../../layouts/_nas/NasFooter";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../hooks/api";
import * as dateFns from "date-fns";
import NasSidebar from "../../layouts/_nas/NasSidebar";
import { Col, Container, ProgressBar, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function NasBulletinList() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    data: [],
    activePage: 1,
    itemPerPage: 2,
    totalItems: Infinity,
    lastPage: Infinity,
  });
  let query = useQuery();

  const pagelocation = t("_bulletins");

  const setLoading = (loadingState) => {
    setIsLoading(loadingState);
  };

  const handlePageChange = (pageNumber) => {
    setState({ ...state, activePage: pageNumber });
  };

  const fetchBulletins = async ({ page_size, page }) => {
    setIsLoading(true);
    try {
      const bulletinResponse = await axiosInstance.get("/public/bulletins", {
        params: {
          page_size,
          page,
          search: query.get("s"),
        },
      });

      console.log(bulletinResponse.data);
      setLoading(false);

      setState({
        ...state,
        data: bulletinResponse.data.data,
        lastPage: bulletinResponse.data.meta.last_page,
        totalItems: bulletinResponse.data.meta.total,
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBulletins({
      page_size: state.itemPerPage,
      page: state.activePage,
    });
  }, [state.activePage]);

  return (
    <>
      <MetaTags>
        <title>Holy Spirit Church | {t(pagelocation)}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <NasHeader />
      <Breadcrumbs breadcrumb={{ pagename: pagelocation }} />
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {!!query.get("s") && (
                <div className="row" style={{ marginBottom: "30px" }}>
                  <div
                    className="sidebar-widget widget-search"
                    style={{ width: "100%" }}
                  >
                    {t("_results")} {t("_for")}:
                    <h6 className="widget-title">
                      <b>{query.get("s")}</b>
                    </h6>
                  </div>
                </div>
              )}
              {isLoading ? (
                <Container>
                  <Row>
                    <Col>
                      <ProgressBar variant="danger" animated now={100} />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <>
                  {/* Bulletins Start */}

                  {state.data.map((item, i) => {
                    return (
                      <div className="row sigma_post sigma_post-list sigma_bulletin">
                        <div className="col-12 col-lg-2 align-self-center">
                          <Link to={"/bulletin-details/" + item.hashid}>
                            <img
                              className="align-self-center"
                              src="/assets/img/nas/bulletin_icon.png"
                              alt={item.title}
                              style={{ maxWidth: 120, paddingTop: 20 }}
                            />
                          </Link>
                        </div>
                        <div className="col-12 col-lg-10">
                          <article key={`bulletin-${i}`}>
                            <div className="sigma_post-body">
                              <div className="sigma_post-desc">
                                <h5>
                                  <Link to={"/bulletin-details/" + item.hashid}>
                                    {item.title}
                                  </Link>
                                </h5>
                                <span>
                                  <span className="fw-600 mr-2">{`${t(
                                    "_posted"
                                  )} ${t("_on")}`}</span>
                                  <Link
                                    to={"/bulletin-details/" + item.hashid}
                                    className="sigma_post-date"
                                  >
                                    {dateFns.format(
                                      dateFns.parseISO(item.created_at),
                                      "PPP"
                                    )}
                                  </Link>
                                </span>
                                <p>{item.description}</p>
                              </div>
                              <Link
                                to={"/bulletin-details/" + item.hashid}
                                className="sigma_btn-custom"
                              >
                                {t("_read_more")}
                              </Link>
                            </div>
                          </article>
                        </div>
                      </div>
                    );
                  })}
                  {/* Bulletins End */}
                  {/* Pagination Start */}
                  {!isLoading && state.data.length > 0 && (
                    <Pagination
                      activePage={state.activePage}
                      itemsCountPerPage={state.itemPerPage}
                      totalItemsCount={state.totalItems}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                      innerClass="pagination"
                      activeClass="active"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  )}
                </>
              )}
            </div>
            {/* Pagination End */}
            <div className="col-lg-4">
              <NasSidebar isBulletins={true} hideCategories hideTags />
            </div>
          </div>
        </div>
      </div>
      <NasFooter />
    </>
  );
}

export default NasBulletinList;
