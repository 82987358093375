import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import NasHeader from "../../layouts/_nas/NasHeader";
import NasFooter from "../../layouts/_nas/NasFooter";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import NasSidebar from "../../layouts/_nas/NasSidebar";
import { useParams } from "react-router-dom";
import NasPDFViewer from "../../sections/_nas/NasPDFViewer";
import axiosInstance from "../../../hooks/api";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import * as dateFns from "date-fns";

function openSocialPopup(social) {
  window.open(social.link, "MsgWindow", "width=600,height=600");
}

const NasBulletinDetails = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { id: detailId } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [bulletin, setBulletin] = useState();
  const item = {};

  const pagelocation = t("_bulletin_details");

  useEffect(() => {
    const fetchBulletin = async () => {
      try {
        const bulletinResponse = await axiosInstance.get(
          `/public/bulletins/${detailId}`
        );
        setBulletin(bulletinResponse.data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBulletin();
  }, [params]);

  return (
    <>
      <MetaTags>
        <title>Holy Spirit Church | {pagelocation}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <NasHeader />
      <Breadcrumbs breadcrumb={{ pagename: pagelocation }} />
      <section className="section sigma_post-single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {isLoading ? (
                <Container>
                  <Row>
                    <Col>
                      <ProgressBar variant="danger" animated now={100} />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <>
                  <div className="entry-content">
                    <h2>{bulletin.title}</h2>
                    <span style={{ color: "#E02920" }}>
                      {dateFns.format(
                        dateFns.parseISO(bulletin.created_at),
                        "PPP"
                      )}
                    </span>
                    <p>{bulletin.description}</p>
                    {/* <div dangerouslySetInnerHTML={{ __html: item.htmltext }} /> */}
                    <NasPDFViewer targetFile={bulletin.document} />
                  </div>
                  {/* Post Share End */}
                </>
              )}
            </div>
            {/* Sidebar */}
            <aside className="col-lg-4">
              <NasSidebar isBulletins={true} />
            </aside>
            {/* /Sidebar */}
          </div>
        </div>
      </section>
      <NasFooter />
    </>
  );
};

export default NasBulletinDetails;
