// axiosInterceptorInstance.js

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // // Modify the request config here (add headers, authentication tokens)
    // const accessToken =
    //   typeof window !== "undefined" ? localStorage.getItem("_t") : null;

    // // If token is present, add it to request's Authorization Header
    // if (accessToken) {
    //   if (config.headers)
    //     config.headers.Authorization = `Bearer ${accessToken}`;
    // }

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle response errors here
    return Promise.reject(error);
  }
);

export default axiosInstance;
