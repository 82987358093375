import React, { Component, Fragment } from 'react';
import Abouttext from './Abouttext';
import Banner from './Banner';
import Blogs from './Blogs';
// import Donation from './Donation';
import Latestsermon from './Latestsermon';
import Recentevents from './Recentevents';
import Recentsermon from './Recentsermon';
import Latestevent from './Latestevent';
import Sermons from './Sermons';
import Historytestimonials from './Historytestimonials';
import Blockcta from './Blockcta';
import Testimonials from './Testimonials';
import Team from './Team';
import Upcomingevents from './Upcomingevents';
import Gallery from './Gallery';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner />
                <section className="section section-padding mt-negative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <Latestevent />
                            </div>
                            <div className="col-lg-6">
                                <Latestsermon />
                            </div>
                        </div>
                    </div>
                </section>
                {/*<Donation />*/}
                <Abouttext />
                <Sermons />
                <section className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 mb-lg-30">
                                <Recentevents />
                            </div>
                            <div className="col-xl-2 d-none d-xl-flex align-items-center">
                                <div className="sigma_seperator-vertical">
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <Recentsermon />
                            </div>
                        </div>
                    </div>
                </section>
                <Historytestimonials />
                <Blockcta />
                <Testimonials />
                <Gallery />
                <Team />
                <Upcomingevents />
                <Blogs />
            </Fragment>
        );
    }
}

export default Content;