import React, { Component } from "react";
import { Link } from "react-router-dom";
import event from "../../../data/upcomingevent.json";

class Upcomingevents extends Component {
  render() {
    return (
      <section className="section section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title">
                <h6 className="subtitle cursive-style">Events</h6>
                <h4 className="title">Upcoming Events</h4>
                <p>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum' will uncover many web sites still in their
                  infancy. Various versions have evolved over the years
                </p>
                <Link to="/event" className="btn-link">
                  Learn More <i className="far fa-arrow-right" />{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="section pb-0 pl-0 pl-md-5">
                <div className="spacer spacer-lg primary-bg position-absolute primary bg-expand" />
                <div className="table-responsive-md">
                  <table>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Topic</th>
                        <th scope="col">Speaker</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {/* Data */}
                      {event.map((item, i) => (
                        <tr key={i}>
                          <td>{item.topic}</td>
                          <td>{item.speaker}</td>
                          <td>{item.timing}</td>
                        </tr>
                      ))}
                      {/* Data */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="custom-bg"
          style={{
            backgroundImage:
              "url(" + process.env.PUBLIC_URL + "/assets/img/image2.jpg)",
          }}
        />
      </section>
    );
  }
}

export default Upcomingevents;
