import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class Breadcrumbs extends Component {
  render() {
    const { t } = this.props;

    return (
      <div
        className="sigma_subheader"
        style={{
          backgroundImage:
            "url(" +
            process.env.PUBLIC_URL +
            "/assets/img/nas/backdrop_hsc.png)",
        }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <h1>{this.props.breadcrumb.pagename}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="btn-link">
                    {t("_home")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {this.props.breadcrumb.pagename}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Breadcrumbs);
