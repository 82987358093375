import React from 'react';
import banner from "../../../data/homebanner.json";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Videohelper from '../../../helper/Videohelper';

const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: "slick-dots d-flex",
    autoplay: true,
    customPaging: function (i) {
        return (
            <div className="sigma_round-dot" key={i}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve"><circle className="sigma_round-dot-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /><circle className="sigma_round-dot-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /></svg></div>
        );
    },
    responsive: [
        {
            breakpoint: 991,
            settings: {
                dots: false,
            }
        }
    ]
}
class Banner extends Videohelper {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        return (
            <div className="sigma_banner banner-1">
                <Slider ref={c => (this.slider = c)} {...settings} className="sigma_banner-slider">
                    {/* Banner Item Start */}
                    {banner.map((item, i) => (
                        <div key={i}>
                            <div className="sigma_banner-slider-inner dot-pattern bg-cover bg-center bg-norepeat" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/" + item.image + ")" }}>
                                <div className="sigma_banner-text">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-lg-5 d-none d-lg-block">
                                                <div className="sigma_video-popup-wrap">
                                                    <img src={process.env.PUBLIC_URL + "/" + item.videoimage} alt={item.video} />
                                                    <a rel={"external"} href={item.video} className="sigma_video-popup popup-youtube">
                                                        <i className="far fa-play" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="offset-lg-1 col-lg-6">
                                                <span className="cursive-style badge badge-primary">{item.subtitle}</span>
                                                <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
                                                <p>{item.text}</p>
                                                <Link to="/about" className="sigma_btn-custom">Learn More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* Banner Item End */}
                </Slider>
                {/* Custom Arrows Start */}
                <div className="sigma_arrows sigma_arrows-transparent">
                    <div className="sigma_round-arrow prev-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                            <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                            <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                        </svg>
                        <i className="far fa-chevron-left slick-arrow slider-prev" onClick={this.previous} />
                    </div>
                    <div className="sigma_round-arrow next-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                            <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                            <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                        </svg>
                        <i className="far fa-chevron-right slick-arrow slider-next" onClick={this.next} />
                    </div>
                </div>
                {/* Custom Arrows End */}
            </div>
        );
    }
}

export default Banner;