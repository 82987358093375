import React, { Component, Fragment } from 'react';
import { getRecentPost, getDateInitial } from "../../../helper/eventHelper";
import { Link } from 'react-router-dom';

class Latestevent extends Component {
    render() {
        return (
            <Fragment>
                {getRecentPost().slice(0, 1).map((item, i) => (
                    <div className="sigma_block-box crayon-texture-2" key={i}>
                        <h5 className="cursive-style custom-primary">Next Event</h5>
                        <h4><Link to={"/event-details/" + item.id}>{item.title}</Link></h4>
                        <p>{item.shorttext.slice(0, 130)}</p>
                        <div className="sigma_block-content">
                            <div className="sigma_block-date" dangerouslySetInnerHTML={{ __html: getDateInitial(item.date) }} />
                            <div className="sigma_block-text">
                                <ul className="mb-0">
                                    <li><i className="fal fa-clock custom-primary mr-3" />{item.timeday}</li>
                                    <li><i className="fal fa-map-marker custom-primary mr-3" />{item.location}</li>
                                </ul>
                            </div>
                        </div>
                        <Link to={"/event-details/" + item.id} className="sigma_btn-custom">Join Now</Link>
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default Latestevent;