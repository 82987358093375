import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../../helper/navhelper";
import navigation from "../../data/navigation.json";
import Mobilemenu from "./Mobilemenu";
import Canvas from "./Canvas";
import Sidecart from "./Sidecart";

const cartlength = 5;

class Header extends HeaderComponent {
  render() {
    const stickyHeader = this.state.stickyheader ? " sticky" : "";
    return (
      <Fragment>
        {/* Search Form Start*/}
        <div
          className={
            this.state.searchMethod === true
              ? "sigma_search-form-wrapper open"
              : "sigma_search-form-wrapper"
          }
        >
          <div
            className="sigma_search-trigger close-btn"
            onClick={this.toggleSearch}
          >
            <span />
            <span />
          </div>
          <form className="sigma_search-form">
            <input type="text" placeholder="Search..." required />
            <button type="submit" className="sigma_search-btn">
              <i className="fal fa-search" />
            </button>
          </form>
        </div>
        {/* Search Form End*/}
        {/* Cart Sidebar */}
        <div
          className={
            this.state.cartMethod === true
              ? "sigma_cart-sidebar-wrapper sigma_aside sigma_aside-right cart-open"
              : "sigma_cart-sidebar-wrapper sigma_aside sigma_aside-right"
          }
        >
          <aside className="sigma_cart-sidebar">
            <div className="sigma_cart-sidebar-header">
              <h4>Your Cart</h4>
              <div
                className="close-btn sigma_cart-trigger close-dark"
                onClick={this.toggleCart}
              >
                <span />
                <span />
              </div>
            </div>
            <Sidecart />
          </aside>
        </div>
        <div
          className="sigma_aside-overlay sigma_cart-sidebar-overlay sigma_cart-trigger"
          onClick={this.toggleCart}
        />
        {/* Aside (Right Panel) */}
        <aside
          className={
            this.state.canvasMethod === true
              ? "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg open"
              : "sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg"
          }
        >
          <Canvas />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger-right"
          onClick={this.toggleCanvas}
        />
        {/* Aside (Mobile Navigation) */}
        <aside
          className={
            this.state.navmethod === true
              ? "sigma_aside sigma_aside-left open"
              : "sigma_aside sigma_aside-left"
          }
        >
          <Mobilemenu />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger-left"
          onClick={this.toggleNav}
        />
        {/* Header */}
        <header
          className={
            "sigma_header header-3 header-fw can-sticky crayon-texture-1" +
            stickyHeader
          }
        >
          {/* Topheader Start */}
          <div className="sigma_header-top">
            <div className="container">
              <div className="sigma_header-top-inner">
                <ul className="sigma_header-top-links">
                  <li className="menu-item">
                    {" "}
                    <Link to="tel:">
                      {" "}
                      <i className="fal fa-phone" /> (+123) 123 4567 890
                    </Link>{" "}
                  </li>
                  <li className="menu-item">
                    {" "}
                    <Link to="mailto:">
                      {" "}
                      <i className="fal fa-envelope" /> example@example.com
                    </Link>
                  </li>
                </ul>
                <ul className="sigma_header-top-links">
                  <li className="menu-item sigma_live">
                    <Link to="#">
                      <i className="fas fa-circle" /> Live{" "}
                    </Link>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="#"> Language</Link>
                    <ul className="sub-menu sub-menu-left">
                      <li>
                        {" "}
                        <Link to="#">English</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="#">German</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="#">French</Link>{" "}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Topheader End */}
          {/* Middle Header Start */}
          <div className="sigma_header-middle">
            <div className="container">
              <nav className="navbar">
                {/* Logo Start */}
                <div className="sigma_logo-wrapper">
                  <Link to="/" className="navbar-brand">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                      alt="logo"
                    />
                  </Link>
                  {/* Logo Card Start */}
                  <div className="sigma_logo-card style-2 d-none d-md-block">
                    <img
                      className="sigma_logo-card-texture"
                      src={
                        process.env.PUBLIC_URL + "/assets/img/cross-icon.png"
                      }
                      alt="img"
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="sigma_logo-card-logo">
                          <Link to="/" className="navbar-brand">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/logo-light.png"
                              }
                              alt="logo"
                            />
                          </Link>
                        </div>
                        <p>
                          Donec sollicitudin molestie malesuada. Quisque velit
                          nisi, pretium ut lacinia in, elementum id enim.
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <ul className="sigma_logo-card-info">
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-phone" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                {" "}
                                <strong>Call Us:</strong>
                              </p>
                              <p> +123 456 789 </p>
                            </div>
                          </li>
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-envelope" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                {" "}
                                <strong>Email Us:</strong>
                              </p>
                              <p> example@example.com </p>
                            </div>
                          </li>
                          <li className="sigma_logo-card-item">
                            <i className="fal fa-map-marker" />
                            <div className="sigma_logo-card-item-body">
                              <p>
                                {" "}
                                <strong>Find Us</strong>{" "}
                              </p>
                              <p>
                                {" "}
                                <Link to="/contact">
                                  {" "}
                                  Open in Google Maps{" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </li>
                        </ul>
                        <ul className="sigma_sm">
                          <li>
                            {" "}
                            <Link to="#">
                              {" "}
                              <i className="fab fa-facebook-f" />{" "}
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link to="#">
                              {" "}
                              <i className="fab fa-linkedin-in" />{" "}
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link to="#">
                              {" "}
                              <i className="fab fa-youtube" />{" "}
                            </Link>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* Logo Card End */}
                </div>
                {/* Logo End */}
                <div className="sigma_header-controls style-2">
                  <ul className="sigma_header-controls-inner">
                    {/* Desktop Toggler */}
                    <li
                      className="aside-toggler style-2 aside-trigger-right desktop-toggler"
                      onClick={this.toggleCanvas}
                    >
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                    </li>
                  </ul>
                </div>
                {/* Menu */}
                <ul className="navbar-nav">
                  {navigation.length > 0
                    ? navigation.slice(0, 3).map((item, i) => (
                        <li
                          key={i}
                          className={`menu-item ${
                            item.child ? "menu-item-has-children" : ""
                          } `}
                          onClick={this.triggerChild}
                        >
                          {item.child ? (
                            <Link onClick={(e) => e.preventDefault()} to="/">
                              {" "}
                              {item.linkText}{" "}
                            </Link>
                          ) : (
                            <Link to={item.link}> {item.linkText} </Link>
                          )}
                          {item.child ? (
                            <ul className="sub-menu" role="menu">
                              {item.submenu.map((sub_item, i) => (
                                <li
                                  key={i}
                                  className={`menu-item ${
                                    sub_item.child
                                      ? "menu-item-has-children"
                                      : ""
                                  } `}
                                >
                                  {sub_item.child ? (
                                    <Link
                                      onClick={(e) => e.preventDefault()}
                                      to="/"
                                    >
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  ) : (
                                    <Link to={sub_item.link}>
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  )}
                                  {sub_item.submenu ? (
                                    <ul className="sub-menu">
                                      {sub_item.submenu.map((third_item, i) => (
                                        <li className="menu-item" key={i}>
                                          <Link to={third_item.link}>
                                            {third_item.linkText}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ))
                    : null}
                  <li className="menu-item menu-item-has-children mega-menu-wrapper">
                    <Link to="#">Others</Link>
                    <ul className="sub-menu">
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/img/megamenu.jpg"
                          }
                          alt="img"
                        />
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="mega-menu-item">
                                <h6>Basic Pages</h6>
                                <Link to="/about">About Us</Link>
                                <Link to="/pastor-details/1">About Pastor</Link>
                                <Link to="/contact">Contact Us</Link>
                                <Link to="/history">History Slide</Link>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="mega-menu-item">
                                <h6>Specific Pages</h6>
                                <Link to="/sermon">Sermon Archive</Link>
                                <Link to="/sermon-details/1">
                                  Sermon Details
                                </Link>
                                <Link to="/donation">Donations</Link>
                                <Link to="/donation-details/1">
                                  Donation Cause
                                </Link>
                                <Link to="/prayer-wall">Prayer Wall</Link>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mega-menu-item">
                                <h6>Shop Pages</h6>
                                <Link to="/shop">Shop Catalog</Link>
                                <Link to="/shop-details/1">
                                  Product Details
                                </Link>
                                <Link to="/checkout">Checkout</Link>
                                <Link to="/cart">Cart</Link>
                                <Link to="/wishlist">Wish List</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  {navigation.length > 0
                    ? navigation.slice(3, 4).map((item, i) => (
                        <li
                          key={i}
                          className={`menu-item ${
                            item.child ? "menu-item-has-children" : ""
                          } `}
                          onClick={this.triggerChild}
                        >
                          {item.child ? (
                            <Link onClick={(e) => e.preventDefault()} to="/">
                              {" "}
                              {item.linkText}{" "}
                            </Link>
                          ) : (
                            <Link to={item.link}> {item.linkText} </Link>
                          )}
                          {item.child ? (
                            <ul className="sub-menu" role="menu">
                              {item.submenu.map((sub_item, i) => (
                                <li
                                  key={i}
                                  className={`menu-item ${
                                    sub_item.child
                                      ? "menu-item-has-children"
                                      : ""
                                  } `}
                                >
                                  {sub_item.child ? (
                                    <Link
                                      onClick={(e) => e.preventDefault()}
                                      to="/"
                                    >
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  ) : (
                                    <Link to={sub_item.link}>
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  )}
                                  {sub_item.submenu ? (
                                    <ul className="sub-menu">
                                      {sub_item.submenu.map((third_item, i) => (
                                        <li className="menu-item" key={i}>
                                          <Link to={third_item.link}>
                                            {third_item.linkText}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ))
                    : null}
                </ul>
                {/* Controls */}
                <div className="sigma_header-controls style-2">
                  <ul className="sigma_header-controls-inner">
                    <li>
                      <Link
                        to="#"
                        className="sigma_search-trigger"
                        onClick={this.toggleSearch}
                      >
                        <i className="fal fa-search" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="sigma_cart-trigger"
                        onClick={this.toggleCart}
                      >
                        <i className="fal fa-shopping-bag" />
                        <span className="sigma_notification-count">
                          {cartlength}
                        </span>
                      </Link>
                    </li>
                    {/* Mobile Toggler */}
                    <li
                      className="aside-toggler style-2 aside-trigger-left"
                      onClick={this.toggleNav}
                    >
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          {/* Middle Header End */}
          {/* Bottom Header Start */}
          <div className="sigma_header-bottom">
            <div className="container">
              <div className="sigma_header-bottom-inner">
                {/* Menu */}
                <ul className="navbar-nav">
                  {navigation.length > 0
                    ? navigation.slice(4, 7).map((item, i) => (
                        <li
                          key={i}
                          className={`menu-item ${
                            item.child ? "menu-item-has-children" : ""
                          } `}
                          onClick={this.triggerChild}
                        >
                          {item.child ? (
                            <Link onClick={(e) => e.preventDefault()} to="/">
                              {" "}
                              {item.linkText}{" "}
                            </Link>
                          ) : (
                            <Link to={item.link}> {item.linkText} </Link>
                          )}
                          {item.child ? (
                            <ul className="sub-menu" role="menu">
                              {item.submenu.map((sub_item, i) => (
                                <li
                                  key={i}
                                  className={`menu-item ${
                                    sub_item.child
                                      ? "menu-item-has-children"
                                      : ""
                                  } `}
                                >
                                  {sub_item.child ? (
                                    <Link
                                      onClick={(e) => e.preventDefault()}
                                      to="/"
                                    >
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  ) : (
                                    <Link to={sub_item.link}>
                                      {" "}
                                      {sub_item.linkText}{" "}
                                    </Link>
                                  )}
                                  {sub_item.submenu ? (
                                    <ul className="sub-menu">
                                      {sub_item.submenu.map((third_item, i) => (
                                        <li className="menu-item" key={i}>
                                          <Link to={third_item.link}>
                                            {third_item.linkText}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ))
                    : null}
                </ul>
                <ul className="sigma_sm">
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="fab fa-facebook-f" />{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="fab fa-linkedin-in" />{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="fab fa-youtube" />{" "}
                    </Link>{" "}
                  </li>
                </ul>
                <div className="sigma_header-bottom-cta">
                  <Link to="/donation" className="sigma_btn-custom">
                    Donate Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Bottom Header End */}
        </header>
      </Fragment>
    );
  }
}

export default Header;
