import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import NasHeader from "../../layouts/_nas/NasHeader";
import NasFooter from "../../layouts/_nas/NasFooter";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import NasRegisterForm from "../../sections/_nas/NasRegisterForm";

export function NasRegistration() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});

  const pagelocation = t("_registration");

  useEffect(() => {}, []);

  return (
    <>
      <MetaTags>
        <title>Holy Spirit Church | {pagelocation}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <NasHeader />
      <Breadcrumbs breadcrumb={{ pagename: pagelocation }} />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {isLoading ? (
                <Container>
                  <Row>
                    <Col>
                      <ProgressBar variant="danger" animated now={100} />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <NasRegisterForm />
              )}
            </div>
          </div>
        </div>
      </section>
      <NasFooter />
    </>
  );
}

export default NasRegistration;
