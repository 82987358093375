import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import instagram from "../../data/instagram.json";

class Instagram extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    {/* Data */}
                    {instagram.map((item, i) => (
                        <div className="col-lg-2 col-md-4 col-sm-4 col-6" key={i}>
                            <Link to="#" className="sigma_ig-item">
                                <img src={process.env.PUBLIC_URL + "/" + item.image} alt="ig" />
                            </Link>
                        </div>
                    ))}
                    {/* Data */}
                </div>
            </div>
        );
    }
}

export default Instagram;