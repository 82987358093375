import React, { Component } from 'react';
import testimonials from "../../../data/testimonials.json";
import Slider from 'react-slick';

const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    dotsClass: "slick-dots d-flex",
    customPaging: function (i) {
        return (
            <div className="sigma_round-dot"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve"><circle className="sigma_round-dot-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /><circle className="sigma_round-dot-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /></svg></div>
        );
    }
}

class Testimonials extends Component {
    render() {
        return (
            <div className="sigma_verses section pt-0">
                <div className="container">
                    <div className="primary-bg right-expand bg-expand primary">
                        <div className="section">
                            <i className="fal fa-quote-left" />
                            <Slider {...settings} className="sigma_verse-slider">
                                {/* Data */}
                                {testimonials.map((item, i) => (
                                    <div key={i} className="sigma_verse-item">
                                        <h3>{item.comment}</h3>
                                    </div>
                                ))}
                                {/* Data */}
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="spacer spacer-xl spacer-bottom secondary-bg" />
            </div>
        );
    }
}

export default Testimonials;