import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRecentPost } from "../../../helper/blogHelper";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../hooks/api";
import { useWindowHeight } from "@react-hook/window-size";

const NasSidebar = ({
  isBulletins = false,
  hideCategories = false,
  hideTags = false,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchTest] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  const fetchItems = async ({ page_size, page }) => {
    setIsLoading(true);

    const itemsUrlPath = isBulletins
      ? "/public/bulletins"
      : "/public/announcements/latest";
    try {
      const announmentsResponse = await axiosInstance.get(itemsUrlPath, {
        params: {
          page_size,
          page,
          sort: "created_at",
        },
      });

      console.log(announmentsResponse.data);

      setIsLoading(false);
      setItems(announmentsResponse.data.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems({ page_size: 3, page: 1 });
  }, []);

  const onChange = (event) => {
    setSearchTest(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const searchUrl = isBulletins
      ? searchText.length === 0
        ? "/bulletins"
        : `/bulletins?s=${encodeURI(searchText)}`
      : searchText.length === 0
      ? "/blog"
      : `/blog?s=${encodeURI(searchText)}`;

    window.location.replace(searchUrl);
  };

  return (
    <div className="sidebar">
      {/* Search Start */}
      <div className="sidebar-widget widget-search">
        <h6 className="widget-title">{t("_search")}</h6>
        <form onSubmit={handleSubmit} method="Get">
          <div className="sigma_search-adv-input">
            <input
              type="text"
              className="form-control"
              placeholder={`${t("_search")} ${t("_keyword")}`}
              name="searchText"
              value={searchText}
              onChange={onChange}
            />
            <button type="submit" name="button">
              <i className="fa fa-search" />
            </button>
          </div>
        </form>
      </div>
      {/* Search End */}
      {/* Recent Posts Start */}
      <div className="sidebar-widget widget-recent-posts">
        {isLoading ? (
          <div className="row">
            <div className="col-12 text-center">
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h5 className="widget-title">{`${t("_recent")} ${t("_posts")}`}</h5>
            {/* Data */}
            {items.map((item, i) => {
              const itemUrl =
                (isBulletins ? `/bulletin-details/` : `/blog-details/`) +
                item.hashid;

              return (
                <article className="sigma_recent-post" key={i}>
                  {!isBulletins ? (
                    <Link to={itemUrl}>
                      <img src={item.picture} alt={item.title} />
                    </Link>
                  ) : (
                    <>
                      <i
                        className="fal fa-newspaper"
                        style={{ marginRight: "10px", fontSize: "2em" }}
                      />
                    </>
                  )}
                  <div className="sigma_recent-post-body">
                    <h6>
                      <Link to={itemUrl}>{item.title}</Link>
                    </h6>
                    <Link to={itemUrl}>{item.body}</Link>
                  </div>
                </article>
              );
            })}
            {/* Data */}
          </>
        )}
      </div>
      {/* Recent Posts End */}
      {!isBulletins && (
        <div style={{ paddingTop: "30px" }}>
          <Link to={"/archives"} className="sigma_btn-custom secondary">
            {t("_see_archive")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default NasSidebar;
