import React, { Component } from "react";
import { Link } from "react-router-dom";
import instagram from "../../data/instagram.json";

class Canvas extends Component {
  render() {
    return (
      <div className="sidebar d-block">
        <div className="sidebar-widget">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
            className="mb-30"
            alt="img"
          />
          <p>
            Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
            Donec rutrum congue leo eget malesuada. Praesent sapien massa,
            convallis a pellentesque nec, egestas non nisi.
          </p>
        </div>
        {/* Instagram Start */}
        <div className="sidebar-widget">
          <h5 className="widget-title">Instagram</h5>
          <div className="row">
            {/* Data */}
            {instagram.map((item, i) => (
              <div className="col-lg-4 col-md-4 col-sm-4 col-6" key={i}>
                <Link to="#" className="sigma_ig-item">
                  <img
                    src={process.env.PUBLIC_URL + "/" + item.image}
                    alt="ig"
                  />
                </Link>
              </div>
            ))}
            {/* Data */}
          </div>
        </div>
        {/* Instagram End */}
        {/* Social Media Start */}
        <div className="sidebar-widget">
          <h5 className="widget-title">Follow Us</h5>
          <div className="sigma_post-share">
            <ul className="sigma_sm">
              <li>
                <Link to="#" className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="#" className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="fab fa-linkedin-in" />
                </Link>
              </li>
              <li>
                <Link to="#" className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="fab fa-youtube" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Social Media End */}
      </div>
    );
  }
}

export default Canvas;
