import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../../../helper/navhelper";
import navigation from "../../../data/_nas/navigation.json";
import { withTranslation } from "react-i18next";
import NasDonationModal from "./NasDonationModal";

class NasMobileMenu extends HeaderComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    // Donation modal
    this.toggleModal = this.toggleModal.bind(this);
  }

  // Donation modal toggle
  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Link to="/" className="navbar-brand">
          <img src={"/assets/img/nas/logo-white.png"} width="100%" alt="logo" />
        </Link>
        <ul>
          <li className="menu-item" onClick={() => this.toggleModal()}>
            <NasDonationModal
              show={this.state.showModal}
              setShow={() =>
                this.setState({
                  showModal: false,
                })
              }
            />
            <Link to="#">{t("_donate")}</Link>
          </li>
          {navigation.length > 0
            ? navigation.map((item, i) => (
                <li
                  key={i}
                  className={`menu-item ${
                    item.child ? "menu-item-has-children" : ""
                  } `}
                  onClick={this.triggerChild}
                >
                  {item.child ? (
                    <Link onClick={(e) => e.preventDefault()} to="/">
                      {t(item.linkText)}
                    </Link>
                  ) : (
                    <Link to={item.link}>{t(item.linkText)}</Link>
                  )}
                  {item.child ? (
                    <ul className="sub-menu" role="menu">
                      {item.submenu.map((sub_item, i) => (
                        <li
                          key={i}
                          className={`menu-item ${
                            sub_item.child ? "menu-item-has-children" : ""
                          } `}
                        >
                          {sub_item.child ? (
                            <Link onClick={(e) => e.preventDefault()} to="/">
                              {t(sub_item.linkText)}
                            </Link>
                          ) : (
                            <Link to={sub_item.link}>
                              {t(sub_item.linkText)}
                            </Link>
                          )}
                          {sub_item.submenu ? (
                            <ul className="sub-menu">
                              {sub_item.submenu.map((third_item, i) => (
                                <li className="menu-item" key={i}>
                                  <Link to={third_item.link}>
                                    {t(third_item.linkText)}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))
            : null}
        </ul>
      </Fragment>
    );
  }
}

export default withTranslation()(NasMobileMenu);
