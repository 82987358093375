import React from 'react';
import testimonials from "../../../data/testimonials.json";
import { getAuthor } from "../../../helper/helper";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Videohelper from '../../../helper/Videohelper';

const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: "slick-dots d-flex",
    autoplay: true,
    customPaging: function (i) {
        return (
            <div className="sigma_round-dot"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve"><circle className="sigma_round-dot-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /><circle className="sigma_round-dot-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" /></svg></div>
        );
    }
}

class Historytestimonials extends Videohelper {
    render() {
        return (
            <section className="section section-padding pt-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 mb-lg-30">
                            <div className="section-title">
                                <h6 className="subtitle cursive-style">History</h6>
                                <h4 className="title">Our History</h4>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
                                    text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various
                                    versions have evolved over the years</p>
                                <Link to="/about" className="btn-link">Learn More <i className="far fa-arrow-right" /> </Link>
                            </div>
                            <div className="section-title mb-0">
                                <h6 className="subtitle cursive-style">Mission</h6>
                                <h4 className="title">Our Mission</h4>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
                                    text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various
                                    versions have evolved over the years</p>
                                <Link to="/about" className="btn-link">Learn More <i className="far fa-arrow-right" /> </Link>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="d-none d-sm-flex sigma_image-wrap vertical-center position-relative">
                                <div className="sigma_image-box parallax one">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/mission/1.jpg"} alt="img" />
                                </div>
                                <div className="sigma_image-box parallax one">
                                    <div className="sigma_video-popup-wrap">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/mission/2.jpg"} alt="img" />
                                        <a rel={"external"} href={"https://www.youtube.com/watch?v=KH1pcNUTa6U"} className="sigma_video-popup popup-youtube">
                                            <i className="far fa-play" />
                                        </a>
                                    </div>
                                </div>
                                <div className="sigma_image-box parallax one">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/mission/3.jpg"} alt="img" />
                                </div>
                                <div className="sigma_spacer-sm secondary-bg bg-expand" />
                            </div>
                            <div className="sigma_testimonial secondary-bg bg-expand">
                                <Slider {...settings} className="sigma_testimonial-slider">
                                    {/* Data */}
                                    {testimonials.map((item, i) => (
                                        <div key={i} className="sigma_testimonial-inner">
                                            <i className="fal fa-quote-left custom-primary" />
                                            <p className="text-white">{item.comment}</p>
                                            {getAuthor(item.user).map((author, i) => (
                                                <cite className="custom-primary" key={i}>{author.name}</cite>
                                            ))}
                                        </div>
                                    ))}
                                    {/* Data */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Historytestimonials;