import React, { Suspense, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Home from "./components/pages/Home";
import NasBulletins from "./components/pages/_nas/NasBulletinList";
import NasBulletinDetails from "./components/pages/_nas/NasBulletinDetails";
import NasDocumentRequest from "./components/pages/_nas/NasDocumentRequest";
import NasBaptismRequest from "./components/pages/_nas/NasBaptismRequest";
import NasRegistration from "./components/pages/_nas/NasRegistration";
import { NasBaptismClasses } from "./components/pages/_nas/NasBaptismClasses";

// Nas Overwrites
const NasHome = React.lazy(() => import("./components/pages/_nas/NasHome"));
const NasBlogDetails = React.lazy(() =>
  import("./components/pages/_nas/NasBlogDetails")
);
const NasBlog = React.lazy(() => import("./components/pages/_nas/NasBlogList"));
const NasAbout = React.lazy(() => import("./components/pages/_nas/NasAbout"));
const NasHistory = React.lazy(() =>
  import("./components/pages/_nas/NasHistory")
);

// Homepages
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
// Blog
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogmasonry = React.lazy(() => import("./components/pages/Blogmasonry"));
// Pages(About)
const Pastordetails = React.lazy(() =>
  import("./components/pages/Pastordetails")
);
const Prayerwall = React.lazy(() => import("./components/pages/Prayerwall"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Event
const Event = React.lazy(() => import("./components/pages/Event"));
const Eventdetails = React.lazy(() =>
  import("./components/pages/Eventdetails")
);
const Eventextended = React.lazy(() =>
  import("./components/pages/Eventextended")
);
// Sermon
const Sermon = React.lazy(() => import("./components/pages/Sermon"));
const Sermondetails = React.lazy(() =>
  import("./components/pages/Sermondetails")
);

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

// Translations
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "path", "cookie", "htmlTag", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });
i18n.changeLanguage();

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            {/* Nas Overwritten Routes */}
            <Route exact path="/" component={NasHome} />
            <Route
              exact
              path="/document_request"
              component={NasDocumentRequest}
            />
            <Route
              exact
              path="/baptism_request"
              component={NasBaptismRequest}
            />
            {/* <Route
              exact
              path="/baptism_classes"
              component={NasBaptismClasses}
            /> */}
            <Route exact path="/registration" component={NasRegistration} />
            <Route exact path="/bulletins" component={NasBulletins} />
            <Route
              exact
              path="/bulletin-details/:id"
              component={NasBulletinDetails}
            />
            <Route exact path="/blog" component={NasBlog} />
            <Route
              exact
              path="/blog-details/:id"
              component={(props) => (
                <NasBlogDetails {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/archives"
              component={(props) => <NasBlog historyOnly={true} {...props} />}
            />
            <Route
              exact
              path="/archive-details/:id"
              component={(props) => (
                <NasBlogDetails
                  {...props}
                  historyOnly
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              exact
              path="/blog/search/:query"
              component={(props) => (
                <NasBlog {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/cat/:catId"
              component={(props) => (
                <NasBlog {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/tag/:tagId"
              component={(props) => (
                <NasBlog {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/author/:authorId"
              component={(props) => (
                <NasBlog {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/about" component={NasAbout} />
            <Route exact path="/history" component={NasHistory} />

            {/* Home */}
            <Route exact path="/old-home" component={Home} />
            <Route exact path="/home-v2" component={Hometwo} />
            <Route exact path="/home-v3" component={Homethree} />
            {/* Blog */}
            <Route exact path="/blog-list" component={Bloglist} />
            <Route exact path="/blog-masonry" component={Blogmasonry} />
            {/* Pages(About) */}
            <Route
              exact
              path="/pastor-details/:id"
              component={(props) => (
                <Pastordetails {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/prayer-wall" component={Prayerwall} />
            <Route exact path="/contact" component={Contact} />
            {/* Event */}
            <Route exact path="/event" component={Event} />
            <Route
              exact
              path="/event-details/:id"
              component={(props) => (
                <Eventdetails {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/event-extended/:id"
              component={(props) => (
                <Eventextended {...props} key={window.location.pathname} />
              )}
            />
            {/* Sermon */}
            <Route
              exact
              path="/sermon/tag/:tagId"
              component={(props) => (
                <Sermon {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/sermon/author/:authorId"
              component={(props) => (
                <Sermon {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/sermon" component={Sermon} />
            <Route
              exact
              path="/sermon-details/:id"
              component={(props) => (
                <Sermondetails {...props} key={window.location.pathname} />
              )}
            />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
