import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../hooks/api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export default function NasRegisterForm(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();
  const [children, setChildren] = useState([]);
  const [relatives, setRelatives] = useState([]);
  const [groupFlags, setGroupFlags] = useState({
    _reader: { order: 1, value: true },
    _legion_of_mary: { order: 2, value: false },
    _altar_server: { order: 3, value: false },
    _teaching_religion: { order: 4, value: false },
    _usher: { order: 5, value: false },
    _choir: { order: 6, value: false },
    _other: { order: 100, value: false },
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const registerPayload = {
        ...data,
        children,
        family_members: relatives,
        reader: groupFlags._reader.value,
        legion_of_mary: groupFlags._legion_of_mary.value,
        altar_server: groupFlags._altar_server.value,
        choir: groupFlags._choir.value,
        teaching_religion: groupFlags._teaching_religion.value,
        usher: groupFlags._usher.value,
        other: groupFlags._other.value,
      };

      const registrationResponse = await axiosInstance.post(
        "/public/registrations",
        {
          ...registerPayload,
        }
      );

      Swal.fire({
        title: t("_registration_success"),
        timer: 1500,
        background: "#f7f8fa",
      });

      reset();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(relatives);
  }, [relatives]);

  return (
    <div className="section pt-0">
      <div className="container">
        <div className="section-title text-center">
          <h6 className="subtitle cursive-style">{t("_register_form")}</h6>
          <p>{t("_donation_message")}</p>
        </div>
        <div className="sigma_contact-wrap">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="sigma_contact-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_family_name")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("family_name")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_husband_name")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          placeholder={t("_name_placeholder")}
                          {...register("husband_name")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_wife_name")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          placeholder={t("_name_placeholder")}
                          {...register("wife_name")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_phone")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="tel"
                          required
                          className="form-control border"
                          {...register("phone")}
                          placeholder="212-395-9280"
                          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_language")} <em className="text-danger">*</em>
                        </label>
                        <select
                          {...register("language")}
                          required
                          className="form-control border"
                        >
                          <option value={t("_english")}>{t("_english")}</option>
                          <option value={t("_english")}>{t("_spanish")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-3">
                        <label>{t("_children")}</label>
                        <ul className="select_amount">
                          <li
                            onClick={() =>
                              setChildren([...children, { name: "", age: 0 }])
                            }
                          >
                            +
                          </li>
                        </ul>
                      </div>
                    </div>
                    {children.map((child, idx) => (
                      <div
                        className="form-group sa-top-input-form"
                        key={`child-form-${idx}`}
                      >
                        <div className="row">
                          <div className="col-sm-7">
                            <label>
                              {t("_child_name")}
                              <em className="text-danger">*</em>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control border"
                              onChange={(ev) => {
                                setChildren([
                                  ...children.map((ch, childIdx) => ({
                                    ...ch,
                                    name:
                                      idx === childIdx
                                        ? ev.target.value
                                        : ch.name,
                                  })),
                                ]);
                              }}
                              value={child.name}
                            />
                          </div>
                          <div className="col-sm-3">
                            <label>
                              {t("_child_age")}
                              <em className="text-danger">*</em>
                            </label>
                            <select
                              required
                              className="form-control border"
                              onChange={(ev) => {
                                setChildren([
                                  ...children.map((ch, childIdx) => ({
                                    ...ch,
                                    age:
                                      idx === childIdx
                                        ? ev.target.value
                                        : ch.age,
                                  })),
                                ]);
                              }}
                              value={children[idx].age}
                            >
                              {[...Array(16).keys()].map((age) => (
                                <option
                                  key={`child-age-input-${idx}-${age}`}
                                  value={age}
                                >
                                  {age}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <label>
                              <em className="text-white">__</em>
                            </label>
                            <button
                              className="sigma_btn-custom"
                              onClick={() => {
                                setChildren([
                                  ...children.filter(
                                    (targetChild, childIndex) => {
                                      return childIndex !== idx;
                                    }
                                  ),
                                ]);
                              }}
                            >
                              ✖️
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-md-3">
                        <label>{t("_relatives")}</label>
                        <ul className="select_amount">
                          <li
                            onClick={() =>
                              setRelatives([
                                ...relatives,
                                { name: "", relation: "_brother" },
                              ])
                            }
                          >
                            +
                          </li>
                        </ul>
                      </div>
                    </div>
                    {relatives.map((relative, idx) => (
                      <div
                        className="form-group sa-top-input-form"
                        key={`rel-form-${idx}`}
                      >
                        <div className="row">
                          <div className="col-sm-5">
                            <label>
                              {t("_relative_name")}
                              <em className="text-danger">*</em>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control border"
                              onChange={(ev) => {
                                setRelatives([
                                  ...relatives.map((ch, childIdx) => ({
                                    ...ch,
                                    name:
                                      idx === childIdx
                                        ? ev.target.value
                                        : ch.name,
                                  })),
                                ]);
                              }}
                              value={relative.name}
                            />
                          </div>
                          <div className="col-sm-5">
                            <label>
                              {t("_relation")}
                              <em className="text-danger">*</em>
                            </label>
                            <select
                              required
                              className="form-control border"
                              onChange={(ev) => {
                                setRelatives([
                                  ...relatives.map((ch, childIdx) => ({
                                    ...ch,
                                    relation:
                                      idx === childIdx
                                        ? ev.target.value
                                        : ch.relation,
                                  })),
                                ]);
                              }}
                              value={relatives[idx].relation}
                            >
                              <option value={t("_brother")}>
                                {t("_brother")}
                              </option>
                              <option value={t("_sister")}>
                                {t("_sister")}
                              </option>
                              <option value={t("_aunt")}>{t("_aunt")}</option>
                              <option value={t("_uncle")}>{t("_uncle")}</option>
                              <option value={t("_cousin")}>
                                {t("_cousin")}
                              </option>
                              <option value={t("_grandmother")}>
                                {t("_grandmother")}
                              </option>
                              <option value={t("_grandfather")}>
                                {t("_grandfather")}
                              </option>
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <label>
                              <em className="text-white">__</em>
                            </label>
                            <button
                              className="sigma_btn-custom"
                              onClick={() => {
                                setRelatives([
                                  ...relatives.filter(
                                    (targetChild, relIndex) => {
                                      return relIndex !== idx;
                                    }
                                  ),
                                ]);
                              }}
                            >
                              ✖️
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-md-12">
                        <label>{t("_group")}</label>
                        <ul className="select_amount">
                          {Object.keys(groupFlags).map((flagKey, i) => {
                            return (
                              <li
                                key={`group-selection-${i}`}
                                className={
                                  groupFlags[flagKey].value ? "active" : ""
                                }
                                onClick={() => {
                                  setGroupFlags((prevValue) => {
                                    const newValue = {
                                      ...prevValue[flagKey],
                                      value: !prevValue[flagKey].value,
                                    };
                                    return {
                                      ...prevValue,
                                      [flagKey]: newValue,
                                    };
                                  });
                                }}
                              >
                                {t(flagKey)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_address_1")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="address_line_1"
                          className="form-control border"
                          placeholder="42"
                          {...register("address_line_1")}
                        />
                      </div>
                      <div className="col-sm-12">
                        <label>
                          {t("_address_2")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          name="address_line_2"
                          className="form-control border"
                          placeholder="Wallaby Way"
                          {...register("address_line_2")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          {t("_city")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="city"
                          className="form-control border"
                          placeholder="Sydney"
                          {...register("city")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_state")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="state"
                          className="form-control border"
                          placeholder="Sydney"
                          {...register("state")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_zip")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          name="zip"
                          className="form-control border"
                          placeholder="42"
                          {...register("zip")}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={
                      isLoading ? "sigma_btn-custom" : "sigma_btn-custom light"
                    }
                    disabled={isLoading}
                    style={{
                      backgroundColor: isLoading ? "grey" : "",
                    }}
                  >
                    {t("_submit")}
                  </button>
                  {/* Form Messages */}
                  <Alert
                    variant="success"
                    className="d-none mt-3 mb-0"
                    id="server_response_success"
                  >
                    <strong>{t("_success")}!</strong> {t("_contact_form_sent")}
                  </Alert>
                  <Alert
                    variant="danger"
                    className="d-none mt-3 mb-0"
                    id="server_response_danger"
                  >
                    <strong>{t("_oops")}</strong> {t("_something_went_wrong")}
                  </Alert>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
