import React, { Component } from 'react';
import authorpost from "../../../data/author.json";
import { Link } from 'react-router-dom';

class Team extends Component {
    render() {
        return (
            <section className="section section-padding">
                <div className="container">
                    <div className="section-title text-center">
                        <h6 className="subtitle cursive-style">Team</h6>
                        <h4 className="title">Our Pastors</h4>
                        <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years</p>
                        <Link to="/about" className="btn-link">Learn More <i className="far fa-arrow-right" /> </Link>
                    </div>
                    <div className="row">
                        {/* Data */}
                        {authorpost.slice(0, 4).map((item, i) => (
                            <div className="col-lg-3 col-md-6" key={i}>
                                <div className="sigma_team team-4">
                                    <div className="sigma_team-thumb">
                                        <img src={process.env.PUBLIC_URL + "/" + item.image} alt={item.name} />
                                    </div>
                                    <div className="sigma_team-body">
                                        <div className="sigma_team-info">
                                            <h5><Link to={"/pastor-details/" + item.id}>{item.name}</Link></h5>
                                            <p>{item.post}</p>
                                        </div>
                                        <div className="sigma_team-sm">
                                            <ul className="sigma_sm">
                                                {/* Data */}
                                                {item.social.map((social, i) => (
                                                    <li key={i}>
                                                        <Link to={social.url} className="sigma_round-button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                                                <circle className="sigma_round-button-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                                                <circle className="sigma_round-button-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                                            </svg>
                                                            <i className={social.icon} />
                                                        </Link>
                                                    </li>
                                                ))}
                                                {/* Data */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* Data */}
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;