const location = "1940 University Ave, Bronx, NY 10453, #205";
const googleMapsLink =
  "https://www.google.com/maps/place/1940+University+Ave,+Bronx,+NY+10453,+USA/@40.8538313,-73.9141195";
const phone = "+1 718-583-0120";
const contactEmail = "holyspiritbx@yahoo.com";

const fb = {
  link: "https://www.facebook.com/groups/74773065920",
};
const ig = {
  link: "https://www.instagram.com/nas_software_corp/",
};
const yt = {
  link: "https://www.youtube.com/@IglesiaESBX",
};
const li = {
  link: "https://www.linkedin.com/groups/9156808/",
};

const churchData = {
  location,
  googleMapsLink,
  phone,
  contactEmail,
  fb,
  ig,
  yt,
  li,
};

export default churchData;
