import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-owl-carousel2/lib/styles.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/magnific-popup/dist/magnific-popup.css";
// import "../node_modules/react-circular-progressbar/dist/styles.css";
import "./assets/fonts/flaticon/flaticon.css";
import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./index.css";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("tilakam"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
