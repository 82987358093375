import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import blogpost from "../../../data/blog/blog.json";

const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        return (
            <div className="section pt-0">
                <div className="container">
                    <div className="row align-items-center sigma_expaneded-row sigma_related-posts">
                        {/* Section Header Start */}
                        <div className="col-lg-4">
                            <div className="section-title">
                                <h6 className="subtitle secondary cursive-style">Blog</h6>
                                <h4 className="title">Latest News</h4>
                                <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                            </div>
                            {/* Custom Arrows Start */}
                            <div className="sigma_arrows sigma_arrows-transparent sigma_arrows-dark mb-lg-30">
                                <div className="sigma_round-arrow prev-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                        <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                        <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                    </svg>
                                    <i className="far fa-chevron-left slick-arrow slider-prev" onClick={this.previous} />
                                </div>
                                <div className="sigma_round-arrow next-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{  }} viewBox="0 0 197 197" enableBackground="new 0 0 197 197" xmlSpace="preserve">
                                        <circle className="sigma_round-arrow-stroke" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                        <circle className="sigma_round-arrow-circle" strokeLinecap="round" cx="98.5" cy="98.6" r="97.5" />
                                    </svg>
                                    <i className="far fa-chevron-right slick-arrow slider-next" onClick={this.next} />
                                </div>
                            </div>
                            {/* Custom Arrows End */}
                        </div>
                        {/* Section Header End */}
                        {/* Blog Post Start */}
                        <div className="px-3 w-100 position-relative">
                            <Slider ref={c => (this.slider = c)} {...settings} className="sigma_related-slider">
                                {/* Article Start */}
                                {blogpost.map((item, i) => (
                                    <article key={i} className="sigma_post">
                                        <div className="sigma_post-thumb">
                                            <Link to={"/blog-details/" + item.id}>
                                                <img src={process.env.PUBLIC_URL + "/" + item.image} alt={item.title} />
                                            </Link>
                                        </div>
                                        <div className="sigma_post-body text-center">
                                            <div className="sigma_post-desc">
                                                <span><Link to={"/blog-details/" + item.id} className="sigma_post-date">{item.postdate}</Link> </span>
                                                <h5> <Link to={"/blog-details/" + item.id}>{item.title}</Link> </h5>
                                                <p>{item.shorttext.slice(0, 90)}</p>
                                            </div>
                                            <Link to={"/blog-details/" + item.id} className="sigma_btn-custom"> Read More </Link>
                                        </div>
                                    </article>
                                ))}
                                {/* Article End */}
                            </Slider>
                        </div>
                        {/* Blog Post End */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Blogs;