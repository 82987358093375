import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cartItems from "../../data/shop/shop.json";
import { handleDeleteFromCart } from "../../helper/shopHelper";

class Sidecart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartItems: cartItems,
            priceTotal: cartItems.slice(0, 5).reduce((totalPrice, item) => totalPrice + item.price * (100 - item.discount) / 100 * item.rating, 0),
        };
    }
    render() {
        return (
            <Fragment>
                <div className="sigma_cart-sidebar-body">
                    {cartItems.slice(0, 5).map((item, i) => (
                        <div className="sigma_cart-sidebar-item" key={i}>
                            <div className="sigma_cart-sidebar-item-body">
                                <Link to={"/shop-details/" + item.id}><img src={process.env.PUBLIC_URL + "/" + item.image[0]} alt={item.title} /></Link>
                                <div className="sigma_cart-sidebar-item-body-inner">
                                    <h5> <Link to={"/shop-details/" + item.id}>{item.title}</Link> </h5>
                                    <span>{item.rating}x {item.price * (100 - item.discount) / 100}$</span>
                                </div>
                            </div>
                            <div className="sigma_cart-sidebar-price">
                                {item.price * (100 - item.discount) / 100 * item.rating}$
                            </div>
                            <div className="close-btn close-dark" onClick={handleDeleteFromCart}>
                                <span />
                                <span />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="sigma_cart-sidebar-footer">
                    <h4>Total: <span>{new Intl.NumberFormat().format((this.state.priceTotal + 2.99).toFixed(2))}$</span> </h4>
                    <Link to="/checkout" className="sigma_btn-custom">Checkout</Link>
                </div>
            </Fragment>
        );
    }
}

export default Sidecart;