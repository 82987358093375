import React, { Component } from "react";
import { Link } from "react-router-dom";
import category from "../../data/category.json";

class Footer extends Component {
  render() {
    return (
      <footer className="sigma_footer footer-2">
        {/* Top Footer */}
        <div className="sigma_footer-top secondary-bg">
          <div className="container">
            <div className="sigma_footer-logo sigma_footer-top-item">
              <img src={"/assets/img/nas/logo-white.png"} alt="logo" />
            </div>
            <div className="sigma_footer-newsletter sigma_footer-top-item">
              <form>
                <input
                  type="text"
                  name="newsletter_email"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="btn-link" name="button">
                  {" "}
                  Submit <i className="far fa-caret-right" />{" "}
                </button>
              </form>
            </div>
            <div className="sigma_footer-sm sigma_footer-top-item">
              <ul className="sigma_sm">
                <li>
                  {" "}
                  <Link to="#">
                    {" "}
                    <i className="fab fa-facebook-f" />{" "}
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#">
                    {" "}
                    <i className="fab fa-linkedin-in" />{" "}
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#">
                    {" "}
                    <i className="fab fa-youtube" />{" "}
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#">
                    {" "}
                    <i className="fab fa-instagram" />{" "}
                  </Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Middle Footer */}
        <div className="sigma_footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Contact Us</h5>
                <p>9000 Regency Parkway, Suite 400 Cary, NC 27518</p>
                <p>
                  E:{" "}
                  <a rel={"external"} href={"mailto:example@example.com"}>
                    example@example.com
                  </a>
                </p>
                <p>P: +000 1456 6978 111</p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Information</h5>
                <ul>
                  <li>
                    {" "}
                    <Link to="/">Home</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/blog">Blog</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/about">About Us</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/shop">Shop</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/contact">Contact Us</Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Others</h5>
                <ul>
                  <li>
                    {" "}
                    <Link to="/sermon">Sermons</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/event">Events</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/donation">Donations</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/shop">Products</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/blog">Blog</Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-none d-lg-block footer-widget">
                <h5 className="widget-title">Top Categories</h5>
                <ul>
                  {/* Data */}
                  {category.slice(0, 5).map((item, i) => (
                    <li key={i}>
                      <Link to={"/blog/cat/" + item.id}>{item.title}</Link>
                    </li>
                  ))}
                  {/* Data */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="sigma_footer-bottom">
          <div className="container">
            <ul>
              <li>
                {" "}
                <Link to="#">Privacy Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">Refund Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">Cookie Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">Terms &amp; Conditions</Link>{" "}
              </li>
            </ul>
            <div className="sigma_footer-copyright">
              <p> Copyright © 2021. All Rights Reserved. </p>
              <Link
                to="#"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                className="sigma_back-to-top"
              >
                Back to top
                <div className="sigma_round-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    style={{}}
                    viewBox="0 0 197 197"
                    enableBackground="new 0 0 197 197"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="sigma_round-button-stroke"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                    <circle
                      className="sigma_round-button-circle"
                      strokeLinecap="round"
                      cx="98.5"
                      cy="98.6"
                      r="97.5"
                    />
                  </svg>
                  <i className="far fa-chevron-up" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
