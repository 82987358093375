import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

const NasDonationModal = ({ show, setShow, ...otherProps }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} centered onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>{t("_choose_recipient")}</Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>{t("_which_option_recipient")}</Modal.Body> */}
      <Modal.Footer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <a
          href="https://www.wesharegiving.org/app/giving/WeShare-20000105?tab=home"
          className="text-white sigma_btn-custom "
          rel="noreferrer"
          target="_blank"
        >
          WeAreGiving.org
        </a>
        <a
          href="https://secure.archny.org/site/Donation2?df_id=6266&mfc_pref=T&6266.donation=form1&_ga=2.17607929.1924105313.1714405571-1169578734.1714234250&_gl=1*1xwzlxr*_ga*MTE2OTU3ODczNC4xNzE0MjM0MjUw*_ga_RDSS4X0HSG*MTcxNDQwNTU3MS4zLjAuMTcxNDQwNTU3OC4wLjAuMA.."
          className="text-white sigma_btn-custom "
          rel="noreferrer"
          target="_blank"
        >
          Cardinal's Appeal
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default NasDonationModal;
