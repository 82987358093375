import React, { Component, Fragment } from 'react';
import { getRecentPost } from "../../../helper/sermonHelper";
import { getAuthor } from "../../../helper/helper";
import { Link } from 'react-router-dom';

class Latestsermon extends Component {
    render() {
        return (
            <Fragment>
                {getRecentPost().slice(0, 1).map((item, i) => (
                    <div className="sigma_block-box crayon-texture-2" key={i}>
                        <h5 className="cursive-style custom-primary">Latest Sermons</h5>
                        <h4><Link to={"/sermon-details/" + item.id}>{item.title}</Link></h4>
                        <div className="sigma_block-content">
                            <div className="sigma_block-text">
                                <ul className="mb-0">
                                    {getAuthor(item.author).map((author, i) => (
                                        <li key={i}>
                                            <img src={process.env.PUBLIC_URL + "/" + author.image} alt={author.name} />
                                            Message from
                                            <Link to={"/sermon/author/" + author.id} className="fw-600"> {author.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <ul className="custom_flex sigma_block-icons">
                            {/* Data */}
                            {item.includes.map((include, i) => (
                                <li key={i}>
                                    <a rel={"external"} href={include.url} className="vertical-center">
                                        <i className={include.icon} />
                                    </a>
                                </li>
                            ))}
                            {/* Data */}
                        </ul>
                        {/* Audio Player Start */}
                        <div className="sigma_audio-player" data-audio="http://www.lukeduncan.me/oslo.mp3">
                            <div className="controls play-controls">
                                <div className="play-container">
                                    <div className="toggle-play play">
                                        <i className="fal fa-play play-trigger" />
                                        <i className="fal fa-pause pause-trigger" />
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-wrap">
                                <div className="time">
                                    <div className="current">0:00</div>
                                    <div className="divider">/</div>
                                    <div className="length" />
                                </div>
                                <div className="timeline">
                                    <div className="progress" />
                                </div>
                            </div>
                            <div className="controls">
                                <div className="volume-container">
                                    <div className="volume-button">
                                        <div className="volume fal fa-volume-up" />
                                    </div>
                                    <div className="volume-slider">
                                        <div className="volume-percentage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Audio Player End */}
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default Latestsermon;