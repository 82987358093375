import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axiosInstance from "../../../hooks/api";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const NasBaptismForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [birthDate, setBirthDate] = useState("baptism");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const baptismRequestPayload = {
        birth_date: birthDate,
        child_name: data.child_name,
        birth_place: data.birth_place,
        mother_name: data.mother_name,
        mother_phone: data.mother_phone,
        mother_email: data.mother_email,
        father_name: data.father_name,
        father_phone: data.father_phone,
        father_email: data.father_email,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        godfather_name: data.godfather_name,
        godfather_religion: data.godfather_religion,
        godfather_phone: data.godfather_phone,
        godfather_email: data.godfather_email,
        godmother_name: data.godmother_name,
        godmother_religion: data.godmother_religion,
        godmother_phone: data.godmother_phone,
        godmother_email: data.godmother_email,
      };

      setIsLoading(true);

      const baptismSubmissionResponse = await axiosInstance.post(
        "/public/baptism",
        { ...baptismRequestPayload }
      );

      reset();

      Swal.fire({
        title: t("_request_placed"),
        text: t("_stop_by_to_complete"),
        confirmButtonColor: "#032C65",
        confirmButtonText: t("_understood"),
        background: "#f7f8fa",
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="section pt-0">
      <div className="container">
        <div className="section-title text-center">
          <h6 className="subtitle cursive-style">
            {t("_baptism_request_form")}
          </h6>
          <p>{t("_donation_message")}</p>
        </div>
        <div className="sigma_contact-wrap">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-12">
              <div className="sigma_contact-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-12">
                        <label>
                          <em className="text-danger">
                            * {t("_bring_birth_cert")}
                          </em>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_child_name")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("child_name")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_birth_date")} <em className="text-danger">*</em>
                        </label>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateField
                            name="birth_date"
                            required
                            onChange={(v) => {
                              setBirthDate(v.format("YYYY-MM-DD"));
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_birth_place")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          placeholder="P. Sherman, 42 Wallaby Way, Sydney"
                          {...register("birth_place")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          {t("_mother_name")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("mother_name")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_mother_phone")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="tel"
                          required
                          className="form-control border"
                          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          {...register("mother_phone")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_mother_email")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="email"
                          required
                          className="form-control border"
                          {...register("mother_email")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          {t("_father_name")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("father_name")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_father_phone")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="tel"
                          required
                          className="form-control border"
                          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          {...register("father_phone")}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label>
                          {t("_father_email")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="email"
                          required
                          className="form-control border"
                          {...register("father_email")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-12">
                        <label>
                          {t("_address_1")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          placeholder="42"
                          {...register("address_line_1")}
                        />
                      </div>
                      <div className="col-sm-12">
                        <label>
                          {t("_address_2")} <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          className="form-control border"
                          placeholder="Wallaby Way"
                          {...register("address_line_2")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-12">
                        <label>
                          <em className="text-danger">
                            * {t("_godparents_religion_req")}
                          </em>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_godmother_name")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godmother_name")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_godmother_religion")}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godmother_religion")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_godmother_phone")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godmother_phone")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_godmother_email")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godmother_email")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_godfather_name")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godfather_name")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_godfather_religion")}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godfather_religion")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group sa-top-input-form">
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          {t("_godfather_phone")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godfather_phone")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {t("_godfather_email")}{" "}
                          <em className="text-danger">*</em>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control border"
                          {...register("godfather_email")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className={
                        isLoading
                          ? "sigma_btn-custom"
                          : "sigma_btn-custom light"
                      }
                      disabled={isLoading}
                      style={{
                        backgroundColor: isLoading ? "grey" : "",
                      }}
                    >
                      {t("_register_for_baptism")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NasBaptismForm;
